.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 85px;
  font-family: "CabinetGrotesk-Medium", Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: black;
}
