.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__content {
  max-width: 496px;
  width: 100%;
}

.modal__content-form {
  position: relative;
  background-color: white;
  max-width: 496px;
  /* width: 100%; */
  border-radius: 20px;
  padding: 28px 28px;
  font-family: "CabinetGrotesk-Medium", Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: black;
}

.modal__close-button {
  width: 20px;
  height: 20px;
  /* background-image: url("../../images/Image\ Modal\ Close\ Button.svg"); */
  background-image: url("../../images/grey_close_button.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  top: 43px;
  left: calc(100% + -48px);
  z-index: 1;
}

.modal__confirm-close {
  width: 20px;
  height: 20px;
  background-image: url("../../images/grey_close_button.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  top: -40px;
  left: calc(50% + -2px);
  z-index: 1;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 24px;
}

.modal__input {
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 4px;
  margin-bottom: 24px;
  font-size: 16px;
}

.modal__input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.modal__label_header {
  margin: 24px 0 21px;
}

.modal__label_selectors {
  margin-left: 0px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.5);
}

input[type="radio"] {
  cursor: pointer;
}

input[type="radio"]:checked + label {
  color: #000;
  appearance: none;
}

.modal__button {
  width: 124px;
  height: 36px;
  margin-top: 34px;
  margin-bottom: 8px;
  background-color: #000;
  border-radius: 4px;
  color: rgba(225, 225, 225, 1);
  cursor: pointer;
  text-align: center;
  border: none;
}

.modal__button:disabled {
  opacity: 0.3;
}

.modal__button:hover {
  opacity: 0.5;
}

.modal__button-alt {
  width: 124px;
  height: 36px;
  margin-top: 34px;
  margin-bottom: 8px;
  background-color: transparent;
  border-radius: 4px;
  color: grey;
  cursor: pointer;
  text-align: center;
  border: none;
}

.modal__confirm-content {
  position: relative;
  background-color: white;
  max-width: 670px;
  width: 100%;
  border-radius: 20px;
  padding: 59px 28px;
  text-align: center;
  font-family: "CabinetGrotesk-Medium", Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: black;
}

.modal__confirm-buttons {
  display: flex;
  flex-direction: column;
}

.modal__confirm-button {
  margin-top: 40px;
  border: none;
  background-color: transparent;
  font-family: "CabinetGrotesk-M", Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(255, 77, 0, 1);
}

.modal__cancel-button {
  margin-top: 24px;
  border: none;
  background-color: transparent;
  font-family: "CabinetGrotesk-M", Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .modal {
    bottom: -182px;
  }

  .modal__content {
    max-height: 455px;
    min-width: 375px;
    width: 100%;
    padding: 32px 16px 32px;
  }

  .modal__close-button {
    background-image: url("../../images/Mobile\ Close\ Button.png");
  }

  .modal__title {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 35px;
  }

  .modal__input {
    padding-top: 12px;
    padding-bottom: 4px;
  }

  .modal__label_header {
    margin: 0px 0px 12px;
  }

  input[type="radio"] {
    margin-bottom: 8px;
  }

  .modal__button {
    width: 100%;
    height: 44px;
    margin-top: 22px;
    margin-bottom: 0px;
  }
}
