.main {
  padding: 0 20px;
}

.card_section {
  font-family: "CabinetGrotesk-Bold", Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: black;
}
