.switch {
  display: flex;
  position: relative;
  font-size: 14px;
}

.switch__box {
  border-radius: 25px;
  border: 2px solid rgba(126, 126, 126, 1);
  width: 56px;
  height: 28px;
  appearance: none;
  background-color: white;
}

.switch__box:hover {
  border: 2px solid rgba(0, 0, 1);
}

.switch__slider {
  border-radius: 20px;
  width: 28px;
  height: 28px;
  background-color: black;
  color: white;
  cursor: pointer;
  text-align: center;
  position: absolute;
}

.switch__slider-F {
  top: 3px;
  left: 32px;
}

.switch__slider-C {
  top: 3px;
  left: 4px;
}

.switch__temp-F {
  position: absolute;
  top: -9px;
  left: 42px;
  color: rgba(126, 126, 126, 1);
}

.switch__temp-C {
  position: absolute;
  top: -9px;
  left: 14px;
  color: rgba(126, 126, 126, 1);
}

.switch__active {
  /* font-size: 14px; */
  color: white;
}
