.sidebar__wrapper {
  display: flex;
  flex-direction: column;
  width: 229px;
  height: 138px;
  font-family: "CabinetGrotesk-Bold", Arial, sans-serif;
  font-size: 20px;
  line-height: 24.8px;
  font-weight: 600;
  color: black;
}

.sidebar {
  display: flex;
  width: 229px;
  height: 138px;
}

.sidebar__avatar-logo {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  border: 1px;
}

.sidebar__username {
  display: flex;
  align-items: flex-start;
  margin-left: 16px;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
}

.sidebar__button {
  border: none;
  background-color: transparent;
  margin-top: 8px;
  font-family: "Cabinet-Grotesk", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24.8px;
  cursor: pointer;
}
